import type { DocumentReference } from "firebase/firestore";
import {
  collection,
  doc,
  getDocs,
  getFirestore,
  increment,
  query,
  where,
  writeBatch,
} from "firebase/firestore";

import type { WithDocRef } from "../../../hooks/useQuery";
import type { Station } from "../../../types/station.db";
import { updatedAt } from "../../../util/firebase";

/**
 * Update the network of this station and network station counts.
 */
const updateNetwork = async ({
  station,
  network_id,
}: {
  station: WithDocRef<Station>;
  network_id: string;
}) => {
  let snapshot;
  const db = getFirestore();
  const collectionRef = collection(db, "networks");
  const fromNetwork = doc(collectionRef, station.network_id);
  const toNetwork = doc(collectionRef, network_id);

  // Photos
  const photos: DocumentReference[] = [];
  snapshot = await getDocs(
    query(
      collection(db, "station-photos"),
      where("station_id", "==", station.id)
    )
  );
  snapshot.docs.forEach((doc) => photos.push(doc.ref));

  // Reviews
  const reviews: DocumentReference[] = [];
  snapshot = await getDocs(
    query(
      collection(db, "station-reviews"),
      where("station_id", "==", station.id)
    )
  );
  snapshot.docs.forEach((doc) => reviews.push(doc.ref));

  const batch = writeBatch(db);
  batch.update(fromNetwork, { station_count: increment(-1) });
  batch.update(toNetwork, { station_count: increment(1) });
  batch.update(station.docRef, { network_id, ...updatedAt });
  photos.forEach((docRef) => {
    batch.update(docRef, { network_id });
  });
  reviews.forEach((docRef) => {
    batch.update(docRef, { network_id });
  });
  await batch.commit();
};

export default updateNetwork;
