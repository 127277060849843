import { Stack, TextField } from "@mui/material";
import {
  collection,
  doc,
  serverTimestamp,
  setDoc,
  updateDoc,
} from "firebase/firestore";
import { useSnackbar } from "notistack";
import React, { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import FormDialog from "../../components/dialogs/FormDialog";
import useFirebase from "../../hooks/useFirebase";
import type { WithDocRef } from "../../hooks/useQuery";
import type { Network } from "../../types/network.db";
import { updatedAt } from "../../util/firebase";

function EditNetworkDialog({
  open,
  handleClose,
  network,
}: {
  open: boolean;
  handleClose: () => void;
  network: WithDocRef<Network> | null;
}): JSX.Element {
  const navigate = useNavigate();
  const { database: db } = useFirebase();
  const { enqueueSnackbar } = useSnackbar();

  const {
    handleSubmit,
    control,
    reset,
    formState: { isSubmitting },
  } = useForm({
    reValidateMode: "onSubmit",
  });

  // Initialize the fields
  useEffect(() => {
    if (open) {
      reset({
        name: network?.name ?? "",
        nrel_id: network?.nrel_id ?? "",
      });
    }
  }, [open, reset, network]);

  const onSubmit = async (data: any) => {
    try {
      if (network) {
        await updateDoc(network.docRef, {
          name: data.name.trim(),
          nrel_id: data.nrel_id.trim() || null,
          ...updatedAt,
        });
      } else {
        const docRef = doc(collection(db, "networks"));
        await setDoc(docRef, {
          name: data.name.trim(),
          nrel_id: data.nrel_id.trim() || null,
          station_count: 0,
          review_count: 0,
          rating: 0,
          show_in_filters: true, // Deprecated?
          is_featured_network: false,
          publish: false,
          timestamps: {
            created_at: serverTimestamp(),
            updated_at: serverTimestamp(),
          },
        });
        navigate(`/networks/${docRef.id}`);
      }
    } catch (err: any) {
      console.error(err);
      enqueueSnackbar("Failed to update network", {
        variant: "error",
      });
    } finally {
      handleClose();
    }
  };

  return (
    <FormDialog
      title={network ? "Edit Network" : "Add Network"}
      open={open}
      onCancel={handleClose}
      onSubmit={handleSubmit(onSubmit)}
      isSubmitting={isSubmitting}
    >
      <Stack mt={6} spacing={6}>
        <Controller
          name={"name"}
          control={control}
          rules={{ required: true }}
          render={({ field, fieldState: { error } }) => (
            <TextField
              {...field}
              label="Name"
              helperText={error?.message}
              error={Boolean(error)}
              fullWidth
            />
          )}
        />
        <Controller
          name={"nrel_id"}
          control={control}
          render={({ field, fieldState: { error } }) => (
            <TextField
              {...field}
              label="NREL ID (optional)"
              helperText={error?.message}
              error={Boolean(error)}
              fullWidth
            />
          )}
        />
      </Stack>
    </FormDialog>
  );
}

export default EditNetworkDialog;
