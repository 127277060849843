import { InfoOutlined as InfoIcon } from "@mui/icons-material";
import { Stack, Tooltip, Typography } from "@mui/material";
import {
  collection,
  getDocs,
  getFirestore,
  query,
  where,
} from "firebase/firestore";
import React, { useEffect, useState } from "react";

import {
  PartitionedCard,
  PartitionedCardItem,
} from "../../components/PartitionedCard";
import useLoading from "../../hooks/useLoading";

function SubscriptionCountsCard(): JSX.Element {
  const [expired, setExpired] = useState<string[]>([]);
  const [expiring, setExpiring] = useState<string[]>([]);
  const [active, setActive] = useState<string[]>([]);
  const [total, setTotal] = useState<number | null>();
  const [vehicles, setVehicles] = useState<any[]>([]);
  const [loading, setLoading] = useLoading();

  useEffect(() => {
    setLoading(true);

    const db = getFirestore();
    const collectionRef = collection(db, "users");
    const q = query(collectionRef, where("subscription", "!=", null));
    getDocs(q)
      .then((snapshot) => {
        const expired: string[] = [];
        const expiring: string[] = [];
        const active: string[] = [];
        for (const doc of snapshot.docs) {
          const subscription = doc.data().subscription;
          if (subscription.expired === true) {
            expired.push(doc.id);
          } else if (subscription.auto_renew_enabled === false) {
            expiring.push(doc.id);
          } else {
            active.push(doc.id);
          }
        }
        setExpired(expired);
        setExpiring(expiring);
        setActive(active);
        setTotal(snapshot.docs.length);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [setLoading]);

  useEffect(() => {
    setLoading(true);

    const db = getFirestore();
    const collectionRef = collection(db, "smartcar-vehicles");
    getDocs(collectionRef)
      .then((snapshot) => {
        setVehicles(
          snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }))
        );
      })
      .finally(() => {
        setLoading(false);
      });
  }, [setLoading]);

  console.debug({
    vehicles,
    missing: vehicles.filter(
      (v) => !active.includes(v.user_id) && !expiring.includes(v.user_id)
    ),
  });

  const formatter = new Intl.NumberFormat("en-US");

  return (
    <PartitionedCard title={"Subscriptions"} spacing={2} loading={loading}>
      <PartitionedCardItem title={"Active"}>
        <Stack direction={"row"} alignItems={"center"} spacing={2}>
          <Typography>
            {formatter.format(active.length)} (
            {formatter.format(
              vehicles
                ? vehicles.filter((v) => active.includes(v.user_id)).length
                : 0
            )}{" "}
            connected)
          </Typography>
          <Tooltip
            title={
              "App store subscriptions that are active and will renew at the end of the current billing period."
            }
            placement={"top"}
            arrow
          >
            <InfoIcon color={"primary"} fontSize={"small"} />
          </Tooltip>
        </Stack>
      </PartitionedCardItem>
      <PartitionedCardItem title={"Not Renewing"}>
        <Stack direction={"row"} alignItems={"center"} spacing={2}>
          <Typography>
            {formatter.format(expiring.length)} (
            {formatter.format(
              vehicles
                ? vehicles.filter((v) => expiring.includes(v.user_id)).length
                : 0
            )}{" "}
            connected)
          </Typography>
          <Tooltip
            title={
              "App store subscriptions that are active but will not renew at the end of the current billing period."
            }
            placement={"top"}
            arrow
          >
            <InfoIcon color={"primary"} fontSize={"small"} />
          </Tooltip>
        </Stack>
      </PartitionedCardItem>
      <PartitionedCardItem title={"Expired"}>
        <Typography>{formatter.format(expired.length)}</Typography>
      </PartitionedCardItem>
      <PartitionedCardItem title={"Total"}>
        <Typography>{formatter.format(total ?? 0)}</Typography>
      </PartitionedCardItem>
    </PartitionedCard>
  );
}

export default SubscriptionCountsCard;
