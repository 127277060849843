import {
  Add as AddIcon,
  Wifi as WifiIcon,
  WifiOff as WifiOffIcon,
} from "@mui/icons-material";
import { Button, Chip, Grid, Stack, Typography } from "@mui/material";
import type { GridColumns } from "@mui/x-data-grid";
import { DataGrid } from "@mui/x-data-grid";
import { differenceInMinutes, formatDistanceToNowStrict } from "date-fns";
import type { Timestamp } from "firebase/firestore";
import { orderBy } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDebounce } from "use-debounce";

import { DataGridCard, standardProps } from "../components/data-grid";
import * as columnTypes from "../components/data-grid/columnTypes";
import QuickSearch from "../components/data-grid/QuickSearch";
import Loader from "../components/Loader";
import { PageHeader } from "../components/PageHeader";
import useCollection from "../hooks/useCollection";
import useNavigateOnRowClick from "../hooks/useNavigateOnRowClick";
import { cw } from "../theme/variants";
import type { Beacon } from "../types/beacon.db";
import type { SavedState } from "../types/navigate";
import { androidVersionRegex, appVersionRegex } from "../util/stdlib";

import ExpiresAt from "./beacons/components/ExpiresAt";
import EditBeaconDialog from "./beacons/EditBeaconDialog";

const columns: GridColumns<Beacon> = [
  {
    field: "name",
    headerName: "Name",
    ...columnTypes.flexDefault,
  },
  {
    field: "title",
    headerName: "Title",
    ...columnTypes.flexPrimary,
    minWidth: 350,
    renderCell: (params) => {
      const lastSeen = (params.row.timestamps?.last_seen as any)?.toDate?.();

      if (lastSeen) {
        const now = new Date();
        const delta = differenceInMinutes(now, lastSeen);

        if (delta < 20) {
          return (
            <Stack>
              <Typography>{params.value}</Typography>
              <Stack
                direction={"row"}
                alignItems={"center"}
                gap={1}
                sx={{ color: cw.brandGreen }}
              >
                <WifiIcon fontSize={"inherit"} />
                <Typography variant={"caption"}>Online</Typography>
              </Stack>
            </Stack>
          );
        } else {
          return (
            <Stack>
              <Typography>{params.value}</Typography>
              <Stack
                direction={"row"}
                alignItems={"center"}
                gap={1}
                sx={{ color: "info.main" }}
              >
                <WifiOffIcon fontSize={"inherit"} />
                <Typography variant={"caption"}>Offline</Typography>
              </Stack>
            </Stack>
          );
        }
      }

      return <Typography>{params.value}</Typography>;
    },
  },
  {
    field: "sponsor",
    headerName: "Sponsor",
    ...columnTypes.flexDefault,
    minWidth: 250,
    valueGetter: (params) => params.row.sponsor?.name,
  },
  {
    field: "app_version",
    headerName: "App Version",
    ...columnTypes.flexDefault,
    valueGetter: (params) => {
      const userAgent = params.row.last_user_agent;
      const match = userAgent?.match(appVersionRegex);
      return match?.[0] || null;
    },
    renderCell: (params) =>
      params.value ? (
        <Chip variant={"outlined"} size={"small"} label={params.value} />
      ) : null,
  },
  {
    field: "android_version",
    headerName: "OS Version",
    ...columnTypes.flexDefault,
    valueGetter: (params) => {
      const userAgent = params.row.last_user_agent;
      const match = userAgent?.match(androidVersionRegex);
      return match?.[0] || null;
    },
    renderCell: (params) =>
      params.value ? (
        <Chip variant={"outlined"} size={"small"} label={params.value} />
      ) : null,
  },
  {
    field: "expires_at",
    headerName: "Expiration",
    ...columnTypes.flexDateTime,
    valueGetter: (params) =>
      (params.row.timestamps.expires_at as Timestamp)?.toDate(),
    renderCell: (params) =>
      params.value ? <ExpiresAt value={params.value} /> : null,
  },
  {
    field: "large_promo_url",
    headerName: "Large Promo",
    ...columnTypes.flexBoolean,
    valueGetter: (params) => !!params.value,
  },
  {
    field: "last_seen",
    headerName: "Last seen",
    ...columnTypes.timestamp,
    valueGetter: (params) =>
      (params.row.timestamps.last_seen as Timestamp)?.toDate(),
    valueFormatter: (params) =>
      params.value
        ? formatDistanceToNowStrict(params.value, { addSuffix: true })
        : null,
  },
  {
    field: "created_at",
    headerName: "First created",
    ...columnTypes.createdAt,
  },
];

function Beacons(): JSX.Element {
  const navigate = useNavigate();
  const location = useLocation();

  const [savedState, setSavedState] = useState(
    (location?.state as SavedState) || {}
  );
  const [page, setPage] = useState(savedState.page || 0);
  const [items, loading] = useCollection<Beacon>("beacons", orderBy("name"));
  const [filtered, setFiltered] = useState<Beacon[]>(items);
  const [search, setSearch] = useState<string>(savedState.search || "");
  const [debounced] = useDebounce(search, 200);
  const [open, setOpen] = useState(false);
  const handleRowClick = useNavigateOnRowClick();

  useEffect(() => {
    navigate("", { replace: true, state: savedState });
  }, [navigate, savedState]);

  // Update saved state
  useEffect(() => {
    setSavedState({ page: page, search: search });
  }, [page, search]);

  // Filter items on quick search input
  useEffect(() => {
    if (debounced) {
      const regex = new RegExp(debounced, "i");
      setFiltered(
        items.filter(
          (item) =>
            regex.test(item.name) ||
            regex.test(item.title ?? "") ||
            regex.test(item.sponsor?.name ?? "")
        )
      );
    } else {
      setFiltered(items);
    }
  }, [items, debounced]);

  const maxBeaconNumber = items
    .map((beacon) => parseInt(beacon.name.replaceAll(/[^\d]/gi, "")))
    .filter((n) => !isNaN(n))
    .reduce((a, b) => Math.max(a, b), 0);
  const nextBeaconNumber = maxBeaconNumber + 1;
  const nextBeaconName = `CWB-${nextBeaconNumber.toString().padStart(6, "0")}`;

  return (
    <React.Fragment>
      <PageHeader
        title={"Beacons"}
        action={
          <>
            <Button
              startIcon={<AddIcon />}
              variant="contained"
              fullWidth
              onClick={() => setOpen(true)}
            >
              Add
            </Button>
          </>
        }
      />

      {loading ? (
        <Loader />
      ) : (
        <>
          <Grid container spacing={6}>
            {/*<Grid item xs={12} md={6} xl={4}>*/}
            {/*  <BeaconVersionsCard beacons={filtered} />*/}
            {/*</Grid>*/}
            <Grid item xs={12}>
              <QuickSearch value={search} onChange={setSearch} />

              <DataGridCard>
                <DataGrid
                  rows={filtered}
                  columns={columns}
                  {...standardProps}
                  sx={{
                    ".MuiDataGrid-row:hover": {
                      cursor: "pointer",
                    },
                  }}
                  page={page}
                  onPageChange={setPage}
                  onRowClick={handleRowClick}
                />
              </DataGridCard>
            </Grid>
          </Grid>

          <EditBeaconDialog
            open={open}
            handleClose={() => setOpen(false)}
            nextBeaconName={nextBeaconName}
          />
        </>
      )}
    </React.Fragment>
  );
}

export default Beacons;
