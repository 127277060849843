import { startOfMonth, subMinutes, subMonths } from "date-fns";
import {
  collection,
  getCountFromServer,
  getFirestore,
  query,
  where,
} from "firebase/firestore";
import React, { useEffect, useState } from "react";

import { StatCard, StatCardItem } from "../../components/StatCard";
import useLoading from "../../hooks/useLoading";

function PhotoCountsCard(): JSX.Element {
  const [totalPhotos, setTotalPhotos] = useState<number | null>();
  const [lastMonth, setLastMonth] = useState<number | null>();
  const [thisMonth, setThisMonth] = useState<number | null>();
  const [loading, setLoading] = useLoading();

  useEffect(() => {
    setLoading(true);

    const db = getFirestore();
    const collectionRef = collection(db, "station-photos");
    getCountFromServer(query(collectionRef))
      .then((snapshot) => {
        setTotalPhotos(snapshot.data().count);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [setLoading]);

  useEffect(() => {
    setLoading(true);

    const db = getFirestore();
    const collectionRef = collection(db, "station-photos");

    const now = new Date();
    const startAt = startOfMonth(subMonths(now, 1));
    const endAt = startOfMonth(now);

    const utcStartAt = subMinutes(startAt, startAt.getTimezoneOffset());
    const utcEndAt = subMinutes(endAt, endAt.getTimezoneOffset());

    getCountFromServer(
      query(
        collectionRef,
        where("timestamps.created_at", ">=", utcStartAt),
        where("timestamps.created_at", "<", utcEndAt)
      )
    )
      .then((snapshot) => {
        setLastMonth(snapshot.data().count);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [setLoading]);

  useEffect(() => {
    setLoading(true);

    const db = getFirestore();
    const collectionRef = collection(db, "station-photos");

    const startAt = startOfMonth(new Date());
    const utcStartAt = subMinutes(startAt, startAt.getTimezoneOffset());

    getCountFromServer(
      query(collectionRef, where("timestamps.created_at", ">=", utcStartAt))
    )
      .then((snapshot) => {
        setThisMonth(snapshot.data().count);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [setLoading]);

  const formatter = new Intl.NumberFormat("en-US");

  return (
    <StatCard title={"Photos"} loading={loading}>
      <StatCardItem
        value={totalPhotos ? formatter.format(totalPhotos) : 0}
        caption={"Total"}
      />
      <StatCardItem
        value={lastMonth ? formatter.format(lastMonth) : 0}
        caption={"New last month"}
      />
      <StatCardItem
        value={thisMonth ? formatter.format(thisMonth) : 0}
        caption={"New this month"}
      />
    </StatCard>
  );
}

export default PhotoCountsCard;
